import { BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import './Navbar.css';
import Home from './Home';
import Login from './login1';
import SecondLogin from './login2';


export default function Navbar(){
    // const Home = () => <p></p> //Not Necessary 

    return (
        <Router>
            <div className='navdiv'>
                <nav>
                    <Link to="/">Home</Link>
                    <Link to="/login">Log in</Link>
                    <Link to="/login2">2nd Login</Link>
                </nav>
            </div>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/login2" element={<SecondLogin />} />
            </Routes>
        </Router>
    )
}