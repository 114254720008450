//ARTTU
import React, {useState} from 'react'
import Axios from 'axios'
import './login1.css'

export default function Login(){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginStatus, setLoginStatus] = useState("");
    const login = () => {
        Axios.post("http://localhost:3001/loginUnsecure", {
          username: username,
          password: password,
        }).then((response) => {
          if (response.data.message) {
            setLoginStatus(response.data.message);
          } else {
            setLoginStatus(response.data[0].username);
          }
          console.log(response.data);
        });
      };

    return (
        <div class='container'>

             {/* Backgroud img */}
            <div className="sql_img">
            <form action="/SQL_injection.jpg"/>
            <h1>Login</h1>

           {/* UserName box */}
            <label for='name'><b>Username</b></label>
            <i class="fa fa-user icon"></i>
            <input type='text' placeholder='Enter Username' name='name' required onChange={(e) =>{
          setUsername(e.target.value); }}/>

            {/* Password box */}
            <label for='psw'><b>Password</b></label>
            <i class='fa fa-key icon'></i>
            <input type='password' placeholder='Enter Password' name='psw' required onChange={(e) =>{
          setPassword(e.target.value);
        }}/>

            {/* Button  */}
            <button className="btn" onClick={login}>Login</button>
            <h1>{loginStatus}</h1>
            </div>
        </div>
    )
}