import React from 'react';

export default function Home(){

    return (
        <body>
        <h1>Place Holder</h1>
        <div>
            <p>Welcome</p>
        </div>
        </body>
    )
}
